<template>
  <div>
    <el-calendar>
      <!-- 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法-->
      <template
        slot="dateCell"
        slot-scope="{date, data}"
      >
        <p>
          {{data.day.split('-').slice(1).join('-')}}
        </p>
        <p>
          {{model.length>0?formatStatus(model.find(item=> item.date===data.day).status):''}}
        </p>
        <el-button v-if="!getDate(data)" type="primary" @click="show({date, data},model.find(item=> item.date===data.day).status)">查看</el-button>
        <el-button v-if="!getDate(data)" type="primary" @click="change({date, data},model.find(item=> item.date===data.day).status)">修改</el-button>
        <el-button v-if="!getDate(data)" type="primary" @click="changeDate({date, data},model.find(item=> item.date===data.day).status)">对调</el-button>
      </template>
    </el-calendar>
    <maintain-plan-modal ref="MaintainPlanModal"></maintain-plan-modal>
    <el-dialog
      title="修改假期"
      :visible.sync="dialogVisible"
    >
      <div>当前日期：{{day}}</div>
      <el-select v-model="status">
        <el-option :value="0" label="普通工作日"></el-option>
        <el-option :value="1" label="周六"></el-option>
        <el-option :value="2" label="需要补班的工作日"></el-option>
        <el-option :value="3" label="法定节假日"></el-option>
        <el-option :value="4" label="周日"></el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit(data)">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="计划对调"
      :visible.sync="dateDialogVisible"
    >
      <div>当前日期：{{day}}</div>
      <div>
        交换日期：<el-date-picker v-model="afterDate" :picker-options="pickerOptions" value-format="yyyy-MM-dd"></el-date-picker>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dateDialogVisible = false">取 消</el-button>
        <el-button :loading="loading" type="primary" @click="submitDate(data)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import moment from "moment";
  import MaintainPlanModal from "@/views/maintenance/maintenanceRule/maintenancePlan/MaintainPlanModal";

  export default {
    name: "Calendar",
    components:{MaintainPlanModal},
    data() {
      return {model:[],dialogVisible:false,dateDialogVisible:false,day:" ",status:"",data:{},afterDate:"", pickerOptions: {
                disabledDate(time) {
                  return time.getTime() < new Date(moment().add(+3,"days")).getTime();
                },
              },
              loading:false,
      };
    },
    mounted() {
      this.getData();
    },
    methods:{
      getDate(data) {
        const format = "YYYY-MM-DD"; //日期格式
        const startDate = moment().format(format);
        const diffStart = moment(startDate, format).diff(moment(data.day, format), "days") >=-3 ;
        return diffStart;
      },
      getData() {
        this.$http.get("/holiday-date/list").then(data => {
          this.model = data;
        });
      },
      formatStatus(num) {
        let status = "假";
        switch (num) {
          case 0:
            status="班";
            break;
          case 1:
            status="班";
            break;
          case 2:
            status="班";
            break;
          case 3:
            status="假";
            break;
          case 4:
            status="假";
            break;
        }
        return status;
      },
      change({data},status) {
        this.dialogVisible=true;
        this.day = data.day;
        this.status = status;
        this.data= data;
      },
      changeDate({data},status) {
        this.dateDialogVisible=true;
        this.day = data.day;
        this.status = status;
        this.data= data;
      },
      show({data}) {
        this.$refs.MaintainPlanModal.show(data.day);
      },
      submit(data) {
        this.$http.put("/holiday-date",{status:this.status,date:data.day}).then(()=>{
          this.$message.success("修改成功");
          this.dialogVisible=false;
          this.getData();
        });
      },
      submitDate(data) {
        this.loading=true;
        this.$http.post("/holiday-date/change-date",{beforeDate:data.day,afterDate:this.afterDate}).then(()=>{
          this.$message.success("修改成功");
          this.dateDialogVisible=false;
          this.loading=false;
          this.getData();
        }).catch(()=>{
          this.loading=false;
        });
      },
    },
  };
</script>

<style scoped>

</style>
